const INDEX_NAV_WHITE_LIST = [
  "/learning-data",
  "/my-course",
  "/my-exams",
  "/more-courses",
  "/review",
  "/department-course",
];

// 屏蔽系统头部和跳过系统登录验证
const SYSTEM_BLACK_LIST = ["/reviewTestPaper"];

const TOKEN = "TOKEN";

const TOKEN_EXPIRE = "TOKEN_EXPIRE";

const USER_STATE = "USER_STATE";

const LANGUAGE = "LANGUAGE";

const VIDEOSPEED = [
  {
    key: "0.5",
    label: "0.5X",
  },
  {
    key: "1.0",
    label: "1.0X",
  },
  {
    key: "1.25",
    label: "1.25X",
  },
  {
    key: "1.5",
    label: "1.5X",
  },
  {
    key: "2.0",
    label: "2.0X",
  },
];

const getEnvName = () => {
  const envName: { [key: string]: string } = {
    dev: "local",
    test: "pre",
    prd: "prod",
  };
  return envName[(window as any).environment];
};

const TOKEN_IN_COOKIE = "NEW_LMS_TICKET_" + getEnvName().toUpperCase();

const COURSE_LEARNING_RECORD = "courseLearningRecord";

const TUTORIALS_LEARNING_RECORD = "tutorialsLearningRecord";

export {
  COURSE_LEARNING_RECORD,
  INDEX_NAV_WHITE_LIST,
  LANGUAGE,
  SYSTEM_BLACK_LIST,
  TOKEN,
  TOKEN_EXPIRE,
  TOKEN_IN_COOKIE,
  TUTORIALS_LEARNING_RECORD,
  USER_STATE,
  VIDEOSPEED,
};
