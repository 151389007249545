import { IResolve } from "@/types/communal";
import {
  DeptTutorialsDetailResolveType,
  DeptTutorialsResolveType,
  ICreateTutorialRequest,
  ICreateTutorialResult,
  IDeptTutorialRequest,
  IEditTutorialRequest,
  IEditTutorialResult,
  IIPostedTutorialListRequest,
  IStructures,
  IStructuresRequest,
  IStudentTutorialLearningHistory,
  ITutorialLearningHistoryRequest,
  StudentLearningHistoryType,
  TutorialHistoryListType,
} from "@/types/dept-tutorials";

import { api } from "./api";

/**
 * 获取部门教程详情
 * @param id 教程id
 * @returns
 */
export const getDeptTutorialsDetailApi = async (
  id: string
): Promise<DeptTutorialsDetailResolveType> => {
  return await api.get(`/personal/tutorials/${id}`);
};

/**
 * 获取我发布的部门教程详情
 * @param id 教程id
 * @returns
 */
export const getDeptTutorialsDetailByCreationApi = async (
  id: string
): Promise<DeptTutorialsDetailResolveType> => {
  return await api.get(`/personal/tutorials/creation/${id}`);
};

/**
 * 获取部门教程列表
 * @returns
 */
export const getDeptTutorialsListApi = async (
  params: IDeptTutorialRequest
): Promise<DeptTutorialsResolveType> => {
  return await api.get(`/personal/tutorials`, { params });
};

/**
 * 我發佈的
 * @returns
 */
export const getIPostedTutorialListApi = async (
  params: IIPostedTutorialListRequest
): Promise<DeptTutorialsResolveType> => {
  return await api.get(`/personal/tutorials/creation`, { params });
};

/**
 * 删除教程
 * @param tutorialId 教程ID
 */
export const deleteMyPostedTutorialApi = async (tutorialId: string) => {
  return await api.delete(`/personal/tutorials/${tutorialId}`);
};

/**
 * 教程的观看记录
 */
export const getTutorialLearningHistoryApi = async (
  params: ITutorialLearningHistoryRequest
): Promise<TutorialHistoryListType> => {
  return await api.get("/personal/tutorials/learning/records", { params });
};
/**
 * 新建教程
 * @returns
 */
export const postCreateTutorialApi = async (
  data: ICreateTutorialRequest
): Promise<IResolve<ICreateTutorialResult[]>> => {
  return await api.post(`/personal/tutorials`, data);
};

/**
 * 更新教程
 * @returns
 */
export const putEditTutorialApi = async (
  id: string,
  data: IEditTutorialRequest
): Promise<IResolve<IEditTutorialResult[]>> => {
  return await api.put(`/personal/tutorials/${id}`, data);
};

/**
 * 人员架构
 * @returns
 */
export const getStructuresApi = async (
  params?: IStructuresRequest
): Promise<IResolve<IStructures[]>> => {
  return await api.get(`/structures`, {
    params,
  });
};

export const getStudentLearningHistoryListApi = async (
  tutorialId: string,
  params: ITutorialLearningHistoryRequest
): Promise<StudentLearningHistoryType> => {
  return await api.get(`/personal/tutorials/${tutorialId}/learning/records`, {
    params,
  });
};
