// 建议导入高像素图片，避免宽高样式超过原有像素导致看起来模糊；
export const icons = {
  accountFill: import("./icon_account_fill.png"),
  lockFill: import("./icon_lock_fill.png"),
  eye: import("./icon_eye.png"),
  eyeClose: import("./icon_eye_close.png"),
  backstage: import("./icon_backstage.png"),
  bell: import("./icon_bell.png"),
  learningData: import("./learning_data.png"),
  learningDataActive: import("./learning_data_active.png"),
  moreCourses: import("./more_courses.png"),
  moreCoursesActive: import("./more_courses_active.png"),
  departmentalCourse: import("./departmental_course.png"),
  departmentalCourseActive: import("./departmental_course_active.png"),
  examinationReview: import("./examination_review.png"),
  examinationReviewActive: import("./examination_review_active.png"),
  myCourse: import("./my_course.png"),
  myCourseActive: import("./my_course_active.png"),
  myExamination: import("./my_examination.png"),
  myExaminationActive: import("./my_examination_active.png"),
  emptyExams: import("./empty_exams.png"),
  leftArrow: import("./icon_arrow_left.png"),
  right: import("./icon_right.png"),
  closeBook: import("./icon_close_book.png"),
  openBook: import("./icon_open_book.png"),
  subBook: import("./icon_sub_book.png"),
  time: import("./icon_time.png"),
  process: import("./icon_process.png"),
  funnel: import("./icon_funnel.png"),
  emptyCourse: import("./empty_course.png"),
  emptyScore: import("./empty_score.png"),
  file: import("./icon_file.png"),
  logOut: import("./logout.png"),
  upload: import("./upload.png"),
  search: import("./icon_search.png"),
  more: import("./icon_more.png"),
  loading: import("./loading.png"),
  pdf: import("./file_pdf.png"),
  video: import("./file_video.png"),
  img: import("./file_image.png"),
  group: import("./icon_group.png"),
  no: import("./icon_no.png"),
  teacher: import("./icon_teacher.png"),
  text: import("./icon_teacher.png"),
  timeLine: import("./time_line.png"),
  comment: import("./icon_comment.png"),
  progressBlack: import("./icon_process_black.png"),
  playCircle: import("./icon_play-circle.png"),
  pauseCircle: import("./icon_pause-circle.png"),
  playForward: import("./icon_play-forward.png"),
  backward: import("./icon_backward.png"),
  screamFull: import("./icon_scream-full.png"),
  speaker: import("./icon_speaker.png"),
  downArrow: import("./icon_arrow_down.png"),
  playing: import("./icon_playing.png"),
  exam: import("./icon_exam.png"),
  complete: import("./icon_complete.png"),
  videoCircle: import("./icon_video_circle.png"),
  addCircle: import("./icon_add-circle.png"),
  minusCircle: import("./icon_minus-circle.png"),
  slice: import("./slice.png"),
  copy: import("./copy.png"),
  audio: import("./audio.png"),
  link: import("./icon_link.png"),
  noData: import("./no_data.png"),
  emptyContent: import("./empty_content.png"),
  version: import("./icon_versions.png"),
};

export type IconTypes = keyof typeof icons;
