import { RouteObject } from "react-router-dom";

import Index from "@/pages/index";

import courseVideo from "../course-detail";
import departmentCourse from "../department-course";
import exams from "../exams";
import { RouterGuard } from "../guard";
import learningData from "../learning-data";
import moreCourse from "../more-course";
import myCourse from "../my-course";
import noticeCenter from "../notice-center";
import review from "../review";

export const index: RouteObject = {
  path: "/",
  element: <RouterGuard element={<Index />} />,
  children: [
    exams,
    learningData,
    myCourse,
    review,
    departmentCourse,
    moreCourse,
    noticeCenter,
    courseVideo,
  ],
};
